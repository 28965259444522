import { createRouter, createWebHistory } from 'vue-router'

function generateNames(sitestructure, id) {
  var currentPage = sitestructure.find(o => {
    return o.de.id == id
  });
  return ":page(" + currentPage.de.url + "|" + currentPage.fr.url + "|" + currentPage.it.url + ")"
}


const createFinalRouter = (app, sitestructure, store) => {
  const routes = [
    {
      path: '',
      name: 'Home',
      component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
      meta: {
        pageID: "home"
      }
    },
    // {
    //   path: generateNames(sitestructure, 48),
    //   name: 'Produkt',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/Produkt.vue'),
    //   meta: {
    //     pageID: "product"
    //   }
    // },
    {
      path: generateNames(sitestructure, 26),
      name: 'Vorteile',
      component: () => import(/* webpackChunkName: "about" */ '../views/Vorteile.vue'),
      meta: {
        pageID: "vorteile"
      }
    },
    {
      path: generateNames(sitestructure, 28),
      name: 'Pricing',
      component: () => import(/* webpackChunkName: "about" */ '../views/Pricing.vue'),
      meta: {
        pageID: "pricing"
      }
    },
    {
      path: generateNames(sitestructure, 30),
      name: 'About',
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      meta: {
        pageID: "about"
      }
    },
    {
      path: generateNames(sitestructure, 32),
      name: 'Contact',
      component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
      meta: {
        pageID: "contact"
      }
    },
    {
      path: generateNames(sitestructure, 57),
      name: "Datenschutz",
      component: () => import(/* webpackChunkName: "about" */ '../views/Datenschutz.vue'),
      meta: {
        pageID: "datenschutz"
      }
    },
    {
      path: generateNames(sitestructure, 59),
      name: "Impressum",
      component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue'),
      meta: {
        pageID: "impressum"
      }
    },
    {
      path: generateNames(sitestructure, 258),
      name: "Support",
      component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
      meta: {
        pageID: "support"
      }
    },
    {
      path: generateNames(sitestructure, 55),
      name: "AGB",
      component: () => import(/* webpackChunkName: "about" */ '../views/AGB.vue'),
      meta: {
        pageID: "agb"
      }
    },
    { path: '/vorteile', redirect: { path: '/funktionen' }},
    { path: '/fr/avantages', redirect: { path: '/fr/fonctions' }},
    { path: '/it/vantaggi', redirect: { path: '/it/funzioni' }},
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
      meta: {
        pageID: "notfound"
      }
    }
  ]
  var langRoutes = [
    {
      path: "/:locale(fr|it|)?",
      name: "Base",
      children: routes
    },
    {
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue'),
      meta: {
        pageID: "notfound"
      }
    }
  ]

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: langRoutes,
    scrollBehavior: (to, from) => {
      var hash = to.hash
      if (hash) {
        const el = document.querySelector(hash)
        if (el) {
          return {
            top: el.offsetTop
          }
        }
        else {
          return {
            top: 0
          }
        }
      }
      else {
        return {
          top: 0
        }
      }

    }
  })


  router.beforeEach(async (to, from, next) => {
    if (to.name != "Base") {
      var currPage = await app.config.globalProperties.$loadPage(to.meta.pageID, to.params.locale, true);
      //setting SEO things
      document.title = currPage.seo.title;
      document.documentElement.setAttribute('lang', to.params.locale+"-CH");
    }
    next()
  })
  return router
}
export default createFinalRouter
