<template>
    <div id="header"
         :class="{ open }">
        <div id="mainHeader">
            <div class="maxWidth">
                <div id="logoContainer">
                    <div class="directContainer">
                        <router-link :to="localeRoute('home')"
                                     class="homelink">
                            <WebLogoType />
                        </router-link>
                    </div>

                    <button class="menuButton"
                            @click="switchMenu">
                        <img v-if="open"
                             src="../../assets/icons/close.svg" />
                        <img v-else
                             src="../../assets/icons/hamburger.svg">
                    </button>
                </div>
                <div id="links">
                    <router-link :to="localeRoute('home')"
                                 @click="clickedLink">{{ pageName('home') }}</router-link>
                    <router-link :to="localeRoute('vorteile')"
                                 @click="clickedLink">{{ pageName('vorteile') }}</router-link>
                    <router-link :to="localeRoute('pricing')"
                                 @click="clickedLink">{{ pageName('pricing') }}</router-link>
                    <router-link :to="localeRoute('about')"
                                 @click="clickedLink">{{ pageName('about') }}</router-link>
                    <router-link :to="localeRoute('support')"
                                 @click="clickedLink">{{ pageName('support') }}</router-link>
                    <router-link :to="localeRoute('contact')"
                                 @click="clickedLink">{{ pageName('contact') }}</router-link>
                    <div id="login">
                        <a :href="adminLink">Login</a>
                    </div>
                </div>

                <LanguageSwitcher v-if="$route.meta.pageID" />

            </div>
        </div>
        <MenuFooter />
    </div>
</template>

<script>

import WebLogoType from "@/components/icon/WebLogoType";
import MenuFooter from "./MenuFooter.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";
export default {
    components: { WebLogoType, MenuFooter, LanguageSwitcher },
    data() {
        return {}
    },
    mounted() {
        window.addEventListener("resize", this.resize)
    },
    computed: {
        open() {
            return this.$store.state.menuOpen
        },
    },
    methods: {
        resize() {
            if (window.innerWidth > 650) {
                this.$store.state.menuOpen = false
            }
        },
        login() {
            this.$store.dispatch("login")
        },
        clickedLink(e) {
            if (e.target.classList.contains("router-link-active"))
                this.$store.state.menuOpen = false;
        },
        switchMenu() {
            this.$store.state.menuOpen = !this.open
        }
    },
    watch: {
        $route() {
            this.$store.state.menuOpen = false
        },
        open(to, from) {
            if (to) {
                document.documentElement.style.overflow = "hidden"
                document.body.style.overflow = "hidden"
                document.querySelector("#app").classList.add("menuOpen")
            }
            else {
                document.documentElement.style.overflow = "unset"
                document.body.style.overflow = "unset"
                document.querySelector("#app").classList.remove("menuOpen")


            }
        }
    }
}
</script>

<style lang="scss"
       scoped>
    #header {
        width: 100%;
        position: fixed;
        z-index: 100;
        top: 0px;
        left: 0px;

    }

    #mainHeader {
        line-height: $header-height !important;
        @include defaultFontSize;
        padding: 0px $defaultPadding;
        box-sizing: border-box;
        background-color: $volt;
        width: 100%;
        height: $header-height;
    }

    .homelink {
        font-size: 0px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    svg {
        height: 16.5px;
        display: block;
    }

    .maxWidth {
        display: flex;
        font-weight: 500;
        gap: 15px;

        #logoContainer {
            flex-grow: 2;
            display: flex;
        }

        .directContainer {
            flex-grow: 2;
            position: relative;
            // width: 141px;
            height: 100%;
        }

        #links a {
            text-decoration: none;
            color: black;
            position: relative;

            &.router-link-exact-active::after {
                content: "";
                display: block;
                width: 100%;
                height: 6px;
                background-color: $altBlack;
                position: absolute;
                bottom: 0px;
            }
        }

        #links {
            flex-grow: 0;
            display: flex;
            gap: 25px;

        }

        #login {
            flex-grow: 0;
            // margin-left: 43px;

            a {
                all: unset;
                cursor: pointer;
                width: 109px;
                height: 33px;
                line-height: 33px;
                background-color: rgba(0, 0, 0, 0.05);
                border-radius: 3px;
                display: inline-block;
                text-align: center;
            }

        }
    }

    .menuButton {
        all: unset;
        display: none;
    }

    @media screen and (max-width: $breakpoint) {
        .menuButton {
            display: block;
            width: 50px;
            text-align: right;
            cursor: pointer;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                right: 0px;
                height: 50%;
                transform: translateY(-50%);
                height: 16.5px;
            }

        }

        .maxWidth {
            flex-wrap: wrap;
            width: 100%;
            display: flex;
            flex-grow: 2;
            flex-direction: column;

            #logoContainer {
                flex-grow: 0;
                display: flex;

                a {
                    flex-grow: 2;
                }
            }

            #links {
                flex-direction: column;
                flex-grow: 2;
                justify-content: center;
                text-align: center;
                line-height: 1.5;
                gap: 0px;

                a {
                    // width: 100%;
                    display: block;
                    margin: 0px auto;

                    &.router-link-exact-active::after {
                        content: "";
                        // display: none;
                        width: 100%;
                        height: 3px;
                        background-color: $altBlack;
                        position: absolute;
                        bottom: 0px;
                    }
                }
            }

            #login a {
                height: 50px;
                line-height: 50px;
                padding: 0px 20px;
            }
        }

        #header {
            height: 55px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            &.open {
                height: 100%;
            }
        }

        #mainHeader {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            flex-grow: 2;


        }

        #links {
            font-size: $mobileBigFont;
        }


        .homelink {
            // position: static;
            // transform: unset;
        }

        #logoContainer {

            height: $header-height;

        }


    }
</style>