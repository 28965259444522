<template>
  <div id="switchContainer">
    <div :class="{ listbox__dropdown: true, open: visible }">
      <button
        aria-haspopup="listbox"
        aria-labelledby="listbox-label listbox-button"
        id="listbox-button"
        @click.stop="toggle"
      >
        {{ languages[currLocale].short }}
        <img src="../../assets/icons/arrow.svg" />
      </button>
      <ul
        id="listbox-list"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-button"
      >
        <li
          v-for="(lang, prop) in languages"
          :aria-selected="currLocale == prop"
        >
          <router-link :to="localeRoute($route.meta.pageID, prop)">{{
            lang.name
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listbox__dropdown {
  position: relative;
}

#listbox-button {
  background-color: unset;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

#listbox-list {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateY(50px);
  opacity: 0;
  transition: opacity 300ms;

  pointer-events: none;

  list-style: none;
  margin: 0px;
  padding: 0px;
  background-color: white;
  overflow: hidden;
  border-radius: 4px;

  min-width: 90.6px;

  li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    height: 34px;
    line-height: 34px;
    font-weight: 400;
    font-size: 13px;

    a {
      display: block;
      padding-left: 11px;
    }

    &[aria-selected="true"] a {
      background-color: #292929;
      color: #fff;
    }
  }
}

img {
  width: 9px;
  margin-left: 5px;
  transform: translateY(-2px) rotate(0deg);
  transition: transform 300ms;
}

.open {
  img {
    transform: translateY(-2px) rotate(180deg);
  }

  #listbox-list {
    display: block;
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (hover: hover) {
  li:hover {
    background-color: #292929;
    color: #fff;
  }
}

@media screen and (max-width: $breakpoint) {
  img {
    display: none;
  }

  #listbox-button {
    display: none;
  }

  #listbox-list {
    display: flex !important;
    position: static;
    transform: none;
    background-color: unset;
    gap: 20px;
    justify-content: center;
    padding-bottom: 20px;

    li {
      height: unset;
      opacity: 0.5;

      &[aria-selected="true"] a,
      &:hover {
        background-color: unset;
        color: black;
      }

      &[aria-selected="true"] {
        opacity: 1;
      }

      a {
        background-color: transparent !important;
        color: black;
        padding: 0px;
      }
    }
  }
}
</style>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    document.body.addEventListener("click", () => {
      this.setVisible(false);
    });
  },
  methods: {
    toggle() {
      this.setVisible(!this.visible);
    },
    setVisible(v) {
      this.visible = v;
    },
  },
  computed: {
    languages() {
      return {
        de: {
          name: "Deutsch",
          short: "De",
        },
        fr: {
          name: "Français",
          short: "Fr",
        },
        it: {
          name: "Italiano",
          short: "It",
        },
      };
    },
  },
};
</script>
